// src/components/Chatbot.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Chatbot.css'; // Custom CSS for chat styling

function Chatbot() {
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [options, setOptions] = useState([]);

    // Automatically send "/start" when the component loads
    useEffect(() => {
        sendMessageToBot("/start");
    }, []);

    // Get current time
    const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    // Function to handle sending a message to the bot
    const sendMessageToBot = async (msg) => {
        const time = getCurrentTime();
        try {
            const res = await axios.post('https://api-rest.fr/api/chatbot/', { message: msg }, {
                headers: { 'Content-Type': 'application/json' },
            });

            const botResponse = res.data.response;

            // If the response contains options, display them
            if (botResponse.options) {
                setOptions(botResponse.options);
            } else {
                setOptions([]); // Clear options if no options in response
            }

            setChatHistory(prevHistory => [
                ...prevHistory,
                { user: msg, bot: botResponse.welcome_message || botResponse, time: time }
            ]);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // Handle option click
    const handleOptionClick = (optionValue) => {
        sendMessageToBot(optionValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim()) {
            sendMessageToBot(message);
            setMessage('');
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-box">
                {chatHistory.map((chat, index) => (
                    <div key={index}>
                        <div className="user-message" data-time={chat.time}>{chat.user}</div>
                        <div className="bot-message" data-time={chat.time}>{chat.bot}</div>
                    </div>
                ))}
            </div>
            {options.length > 0 && (
                <div className="options-container">
                    {options.map((option, index) => (
                        <button 
                            key={index} 
                            className="option-button"
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
            <form onSubmit={handleSubmit} className="input-form">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Tapez votre message..."
                    className="message-input"
                />
                <button type="submit" className="send-button">Envoyer</button>
            </form>
        </div>
    );
}

export default Chatbot;
