// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import './HomePage.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [milestones, setMilestones] = useState([
    {
      quarter: 'Q4 2023',
      title: 'Initial Launch',
      description:
        'Launch SmartBot with core features for seamless navigation and customized micro-applications.',
      status: 'completed',
      tasks: [
        { id: 1, name: 'Deploy the AI assistant for intuitive navigation', is_completed: true },
        { id: 2, name: 'Integrate the Smart Wallet for financial management', is_completed: true },
        { id: 3, name: 'Implement Automated Social Networks for simplified account management', is_completed: true },
      ],
      updated_at: '2023-10-01',
    },
    {
      quarter: 'Q1 2024',
      title: 'User Engagement',
      description:
        'Introduce the Missions & Coins system to increase user engagement and retention.',
      status: 'in_progress',
      tasks: [
        { id: 4, name: 'Launch Missions to accumulate coins', is_completed: false },
        { id: 5, name: 'Develop the exclusive rewards system', is_completed: false },
        { id: 6, name: 'Integrate the dashboard to track progress', is_completed: false },
      ],
      updated_at: '2023-11-15',
    },
    {
      quarter: 'Q2 2024',
      title: 'Integration Expansion',
      description:
        'Expand integrations with third-party applications to enrich the SmartBot ecosystem.',
      status: 'upcoming',
      tasks: [
        { id: 7, name: 'Partner with major financial platforms', is_completed: false },
        { id: 8, name: 'Integrate APIs for new micro-applications', is_completed: false },
        { id: 9, name: 'Optimize AI for improved interconnectivity', is_completed: false },
      ],
      updated_at: '2023-12-01',
    },
    {
      quarter: 'Q3 2024',
      title: 'Advanced AI Optimization',
      description:
        'Enhance artificial intelligence to offer a near-automatic user experience.',
      status: 'upcoming',
      tasks: [
        { id: 10, name: 'Upgrade machine learning algorithms', is_completed: false },
        { id: 11, name: 'Increase personalization through behavioral analysis', is_completed: false },
        { id: 12, name: 'Launch predictive features', is_completed: false },
      ],
      updated_at: '2024-01-15',
    },
    {
      quarter: 'Q4 2024',
      title: 'International Expansion',
      description:
        'Expand SmartBot to international markets to grow the user base.',
      status: 'upcoming',
      tasks: [
        { id: 13, name: 'Localize in multiple languages', is_completed: false },
        { id: 14, name: 'Adapt to local regulations', is_completed: false },
        { id: 15, name: 'Launch targeted international marketing campaigns', is_completed: false },
      ],
      updated_at: '2024-02-28',
    },
    {
      quarter: 'Q1 2025',
      title: 'Market Leadership',
      description:
        'Position SmartBot as the go-to platform for automation and intelligent management.',
      status: 'upcoming',
      tasks: [
        { id: 16, name: 'Launch premium features', is_completed: false },
        { id: 17, name: 'Establish strategic partnerships with industry leaders', is_completed: false },
        { id: 18, name: 'Participate in major tech events', is_completed: false },
      ],
      updated_at: '2024-03-30',
    },
  ]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return '#28a745'; // Green
      case 'in_progress':
        return '#ffc107'; // Orange
      case 'upcoming':
        return '#6c757d'; // Gray
      default:
        return '#6c757d';
    }
  };

  return (
    <div>
      {/* Header Section */}
      <header>
        <div className="hero-content">
          <h1>
            Welcome to <span className="highlight">SmartBot</span>
          </h1>
          <p>Your AI assistant for seamless navigation and tailored micro-applications.</p>
          <div className="cta-buttons">
            <a href="/chatbot" className="cta-button">
              Harness the Power of SmartBot 🚀
            </a>
            <a href="/api/demo/" className="cta-button-secondary">
              Try the Demo 🌐
            </a>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="features">
        <h2>AI-Powered, Connected Micro-Applications</h2>
        <p>Explore an interconnected ecosystem designed to simplify your life.</p>

        <div className="feature-grid">
          <div className="feature-item">
            <div className="icon">💼</div>
            <h3>Smart Wallet</h3>
            <p>
              Manage your finances effortlessly, make payments, or track transactions in just a few clicks.
            </p>
          </div>
          <div className="feature-item">
            <div className="icon">📱</div>
            <h3>Automated Social Networks</h3>
            <p>
              Publish, analyze, and manage your social accounts with ease thanks to our AI.
            </p>
          </div>
          <div className="feature-item">
            <div className="icon">🎯</div>
            <h3>Missions & Coins</h3>
            <p>
              Complete tasks, accumulate coins, and exchange them for exclusive rewards.
            </p>
          </div>
        </div>
      </section>

      {/* AI Power Section */}
      <section className="ai-power">
        <h2>Intelligence at the Heart of Your Navigation</h2>
        <p>
          Our AI connects all applications for a near-automatic user experience. Navigate effortlessly; let SmartBot do the rest.
        </p>
        <div className="cta-buttons">
          <a href="/api/integrations/" className="cta-button">
            See Integrations 🤖
          </a>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <h2>How Does It Work?</h2>
        <ol>
          <li>Create your account and connect your favorite applications.</li>
          <li>SmartBot optimizes your interactions and performs tasks for you.</li>
          <li>Track your progress, adjust settings, and reap the benefits!</li>
        </ol>
      </section>

      {/* Roadmap Section */}
      <section className="roadmap">
        <h2>Our Roadmap</h2>
        <div className="timeline">
          {milestones.map((milestone, index) => (
            <div
              key={milestone.quarter}
              className={`milestone ${index % 2 === 0 ? 'left' : 'right'}`}
              data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
            >
              <div
                className="content"
                style={{ borderLeft: `4px solid ${getStatusColor(milestone.status)}` }}
              >
                <h3>
                  {milestone.quarter}: {milestone.title}
                </h3>
                <p>{milestone.description}</p>
                <ul>
                  {milestone.tasks.map((task) => (
                    <li key={task.id}>
                      <input
                        type="checkbox"
                        checked={task.is_completed}
                        readOnly
                        style={{ marginRight: '10px' }}
                      />
                      {task.name}
                    </li>
                  ))}
                </ul>
                <p style={{ fontSize: '0.8rem', color: '#6c757d' }}>
                  Last Updated: {new Date(milestone.updated_at).toLocaleDateString('en-US')}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Footer Section */}
      <footer>
        <p>&copy; 2024 SmartBot. All rights reserved.</p>
        <div className="social-links">
          <a href="#">Instagram</a>
          <a href="#">Twitter</a>
          <a href="#">LinkedIn</a>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
